<template>
	<div :ref="`deliverItem${info.id}`" class="approve-card" @click="$emit('click')">
		<div class="info">
			<div class="orderTitle-id mb-8">
				<span class="blod">{{dataSource.orderType === 3? dataSource.appOrderId: dataSource.dcosOrderId}}</span>
				<!-- TODO -->
                <!-- <span class="status">
					{{ dataSource.deliveryStatus | codeFormat }}
				</span> -->
			</div>
			<div v-if="dataSource.customerName" class="name mb-8">
				{{ dataSource.customerName }}
			</div>
			<div class="tag mb-8">
				<div>
					{{ dataSource.customerType == 1 ? $t('个人') : $t('企业') }}
				</div>
				<template v-if="[1, 0].includes(dataSource.installmentsFlag)">
					<div class="gap"></div>
					<div>
						{{ dataSource.installmentsFlag == 1 ? $t('分期') : $t('全款') }}
					</div>
				</template>
				<template v-if="dataSource.bigType">
					<div class="gap"></div>
					<div class="bigTye">
						{{ $t('大客户') }}
					</div>
				</template>
			</div>
			<div v-if="carInfo" class="car-info mb-8" v-html="carInfo"></div>
			<div class="mb-8" style="color: rgba(13,23,26,0.45)">VIN： {{ dataSource.vin || '--' }}</div>
			<div class="bottom">
				<div class="follow-user">
					<span>{{ $t('交付专员') }}：</span>
					<span>
						{{
							dataSource.deliveryUserName
						}}
					</span>
				</div>
			</div>
			<van-checkbox
				v-if="isShowCheckbox"
				:value="info.checked"
				shape="square"
				class="check-item"
				@click.native.stop="onClick">
			</van-checkbox>
		</div>
	</div>
</template>
<script>
import { mapGetters } from 'vuex'
import { ROLES } from '@/constants/constants'
import store from '@/store'
export default {
  props: {
    info: {
      type: Object,
      default: () => ({})
    },
    isShowCheckbox: {
      type:Boolean,
      default: true
    }
  },
  data() {
    return {
      dataSource: {},
	  carInfo: ''
    }
  },
  computed: {
    ...mapGetters(['dictHash', 'userInfo']),
    // carInfo() {
    //   const {
    //     seriesName,
    //     modelName,
    //     colourName,
    //     interiorName,
    //   } = this.dataSource?.carOrderMaterial || {}
    //   const arr = []
    //   if (seriesName) {
    //     arr.push(`<span>${seriesName}</span>`)
    //   }
    //   if (modelName) {
    //     arr.push(`<span>${modelName}</span>`)
    //   }
    //   if (colourName) {
    //     arr.push(`<span>${colourName}</span>`)
    //   }
    //   if (interiorName) {
    //     arr.push(`<span>${interiorName}</span>`)
    //   }
    //   return arr.join(
    //     '<span class="gap" style="width: 1px;margin: 0 8px;height: 12px;background-color: #d9d9d9;"></span>'
    //   )
    // },
  },
  watch: {
    dataSource: {
      handler(val){
        const { seriesName, modelName, colourName, interiorName } = val.carOrderMaterial || {}
        const arr = []
        if (seriesName) {
          arr.push(`<span>${seriesName}</span>`)
        }
        if (modelName) {
          arr.push(`<span>${modelName}</span>`)
        }
        if (colourName) {
          arr.push(`<span>${colourName}</span>`)
        }
        if (interiorName) {
          arr.push(`<span>${interiorName}</span>`)
        }
        this.carInfo = arr.join('<span class="gap" style="width: 1px;margin: 0 8px;height: 12px;background-color: #d9d9d9;"></span>')
      },
	  deep: true,
    }
  },
  created(){
    this.$set(this,'dataSource',this.info.order)
  },
  methods: {
    onClick(e){
      this.$emit('change', !this.info.checked, this.info)
    },
  }
}
</script>
	<style lang="less" scoped>
	.approve-card{
		background: #fff;
		box-shadow: 0px 4px 12px 0px rgba(0,0,0,0.05);
		border-radius: 8px;
		padding: 16px 12px 0px 12px;
		font-size: 13px;
		.van-ellipsis{
			overflow: hidden;
			white-space: nowrap;
			text-overflow: ellipsis;
		}
		.info{
			position: relative;
			/deep/ .label-component{
				margin: 0 0 8px 0;
			}
			.mb-8 {
				margin-bottom: 8px;
			}
			.car-info {
				display: flex;
				align-items: center;
				color: rgba(13, 23, 26, 0.45);
			}
			.orderTitle-id {
				display: flex;
				align-items: center;
				padding-bottom: 12px;
				border-bottom: 1px solid rgba(13, 23, 26, 0.05);
				line-height: 16px;
				.blod {
					flex: 1;
				}
				.status {
					font-size: 14px;
					color: #029640;
				}
			}
			.tag {
				display: flex;
				align-items: center;
				color: #b9921a;
			}

			.gap {
				width: 1px;
				margin: 0 8px;
				height: 12px;
				background-color: #d9d9d9;
			}
		}
		.bottom{
			display: flex;
			justify-content: flex-end;
			align-items: center;
			border-top: 1px solid rgba(13,23,26,0.05);
			color: rgba(13, 23, 26, 0.45);
			.follow-user{
				font-size: 13px;
				// color: #0D171A;
				line-height: 32px;
				overflow: hidden;
				white-space: nowrap;
				text-overflow: ellipsis;
				.followUp{
					font-size: 14px;
					color: @yellow-text;
					background: @black;
					width: fit-content;
					padding: 2px 6px;
					border-radius: 4px
					// padding-left: 16px;
				}
			}
			.icons{
				display:flex;
				padding: 8px 0 12px 0;
				justify-content: space-between;
				.icon{
					margin-left: 16px;
					&:first-of-type {
						margin-left: 0;
					}
				}
			}
		}
		.check-item{
			position: absolute;
			right: 0px;
			top: calc(50% - 8px);
			z-index: 1;
			/deep/.van-checkbox__icon{
				font-size: 16px;
				.van-icon{
						border: 2px solid rgba(13,23,26,0.45);
				}
			}
			/deep/.van-checkbox__icon--checked .van-icon{
				background-color: #0D171A;
				border-color: #0D171A;
				line-height: 1;
			}
		}
	}
	</style>
	