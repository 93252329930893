<!-- 该页面用于审批，以及重新上传 -->
<template>
    <div class="approve-details">
      <div class="basic-info">
        <Card v-if="displayStatus==='3'&&!isLoading" :info="details" :isShowCheckbox="false"/>
      </div>
        <!--  -->
        <div>
          <div class="common-title">
            <span v-if="isUploadStatus">
              {{ $t('请选择上传客户签署完成的车辆状态告知书') }}
            </span>
            <span v-else>
              <span>{{ type === '1' ? $t('提车确认书') : $t('车辆状态告知书') }}{{ isReject ? $t('已被驳回，请') : '' }}</span>
              <span v-if="isReject">{{ $t('重新上传') }}</span>
            </span>
          </div>
          <FormVue ref="form" class="formVue" labelWidth="8.2em" :isLabelTop="true" :isEdit="isReject || isUploadStatus" inputAlign="left" :list="info" :formData="formData"/>  
        </div>
        <!-- 审核弹窗 -->
        <van-dialog v-model="isShowReview" :title="$t('审核意见')" 
            show-cancel-button 
            :cancel-button-text="$t('取消')" 
            :confirm-button-text="$t('驳回')"
            :close-on-click-overlay="true"
            :before-close="onReview">
            <van-field
              v-model="reviewRemark"
              rows="2"
              autosize
              label=""
              type="textarea"
              maxlength="200"
              :placeholder="$t('请输入驳回原因')"
              show-word-limit
            />
        </van-dialog>
        <!-- 驳回原因 -->
        <div v-if="isReject" class="reject-info">
          <div v-for="field in fieldList" :key="field.key" class="row">
            <span class="label">{{ field.label }}：</span>
            <span class="value">{{ reviewInfo[field.key] }}</span>
          </div>
        </div>
        <div class="common-footer">
            <template v-if="!isReject&&!isUploadStatus">
              <van-button class="btn back" @click="approve(10)">{{$t('驳回')}}</van-button>
              <van-button class="btn submit" @click="approve(5)">{{$t('通过')}}</van-button>
            </template>
            <template v-else>
              <van-button class="btn back" @click="onCancel">{{$t('返回')}}</van-button>
              <van-button class="btn submit" @click="onSubmit">{{$t('提交')}}</van-button>
            </template>
        </div>
    </div>
</template>
<script>
import FormVue from '@/modules/components/form/index.vue'
import deliveryServices from '@/services/deliveryServices.js'
import loading from '@/utils/loading'
import Card from './components/card.vue'

export default {
  components:{ FormVue,Card },
  props: {

  },
  data(){
    return {
      id: '', // 审批id
      orderId: '', // 交车单id
      type: 1, // 1提车确认书 2车辆状态告知书
      details: {
        order: {}
      },
      isShowReview: false,
      reviewRemark: '',
      info:[],
      formData:{
        attachUrl: ''
      },
      isReject: false, // 是否被驳回
      fieldList: [
        {
          label: this.$t('驳回时间'),
          key: 'reviewTime',
          value: ''
        },
        {
          label: this.$t('驳回原因'),
          key: 'refuseReason',
          value: ''
        }
      ],
      reviewInfo:{
        reviewTime: '',
        refuseReason: '',
      },
      isLoading: true,
      isUploadStatus: false, // 是否上传车辆告知书
      displayStatus: 0 // 1:驳回，重新上传 2:新上传 3:审核
    }
  },
  mounted(){
    // 获取详情
    const { type,isReject,refuseReason,reviewTime,attachUrl='',orderId,id,isUploadStatus,displayStatus } = this.$route.query
    this.type = type
    this.orderId = orderId
    this.id = id
    this.displayStatus = displayStatus
    this.isReject = isReject === 'true'
    this.isUploadStatus = isUploadStatus === 'true'
    if (this.displayStatus==='3'){
      this.getDetails()
    }
    this.reviewInfo = { refuseReason,reviewTime }
    this.formData.attachUrl = attachUrl
    this.getInfo()
  },
  methods: {
    // 交车单详情
    async getDetails(){
      loading.showLoading()
      const res = await deliveryServices.deliveryDetail({ id: this.orderId })
      loading.hideLoading()
      this.$set(this.details, 'order', res || {} )
      this.isLoading = false
    },
    // 审批 5通过  10驳回
    approve(type){
      if (type===10){
        this.isShowReview = true
        return 
      }
      this.approveRequest(type)
    },
    // 审核驳回 1：取消、2：驳回
    async onReview(action, done){
      if (['overlay','cancel'].includes(action))done()

      if (action==='confirm'&&!this.reviewRemark.trim()) {
        this.$toast(this.$t('请输入驳回原因'))
        done(false)
        return
      }
      if (['confirm'].indexOf(action)>-1){
        await this.approveRequest(10,this.reviewRemark)
        done()
      }
    },
    // 审批请求 
    async approveRequest(type,reason){
      const params = {
        ids: [this.id],
        result: type,
        refuseReason: reason
      }
      const res = await deliveryServices.errorApproveReview(params)
      this.$toast(this.$t('操作成功'))
      setTimeout(() => {
        this.$router.go(-1)
      }, 500)
    },
    getInfo() {
      const info = [
        {
          label: this.$t(''), 
          field:'attachUrl',
          value:'',
          fileList: getUrlArr(this.formData.attachUrl),
          type: 'upload',
          maxlength: this.type==='1'? 3: 10,
          required: false,
        },
      ]        
      this.info = info
      function getUrlArr(str) {        
        const imgArr = []
        if (str){
          (str.split(',')).map((url=>{
            imgArr.push({
              status:'done',
              message:'',
              url
            })
          }))
        }
        return imgArr
      }
    },
    async onSubmit(){
      if (!this.formData.attachUrl){
        this.$toast('请上传附件')
        return
      }
      console.log(this.formData)
      
      const params ={
        attachUrl: this.formData.attachUrl,
        type: Number(this.type),
        orderId: this.orderId
      }
      console.log('params:',params)
      const res = await deliveryServices.errorApproveSubmit(params)
      this.$toast(this.$t('操作成功'))
      setTimeout(() => {
        this.$router.go(-1)
      }, 500)
    },
    onCancel(){
      this.$router.go(-1)
    }
  }
}
</script>
<style lang="less" scoped>
.approve-details{
    position: relative;
    padding: 16px;
    display: flex;
    flex-direction: column;
    color: #0D171A;
    font-size: 13px;
    padding-bottom: constant(safe-area-inset-bottom);
    padding-bottom: env(safe-area-inset-bottom);

    .reject-info{
      margin-top: 10px;
      .row{
        line-height: 22px;
        .value{
          word-break: break-all;
        }
      }
    }
}
</style>